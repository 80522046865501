import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select';
import * as bootstrap from 'bootstrap';
import GoogleMapReact from 'google-map-react';
import $ from 'jquery';
import ToastModal from "../components/ToastModal";
import UserContext from "../context/useLoginContext";
import usePetTypes from '../hooks/usePetTypes';
import Loading from '../components/Loading';
import { saveFirubox, getPriceDelivery } from '../api/index';
import { ContinueButton, ReturnButton, Disclaimer } from "../util/anyComponents";
import { isValidEmail } from '../util/anyFunctions';
import { defaultProps, arraySize } from '../util/constants';
import marker from '@img/marker.webp';
import firubox_checkout from '@img/firubox_checkout.webp';
import banner2 from '@img/Banner_2_firubox.webp';
import banner1 from '@img/Banner_1_firubox.webp';
import '@styles/Firubox.scss';


const FiruBox = () => {
    const [messageToast, setMessageToast] = useState(null);
    const { user } = useContext(UserContext);
    const [typePets, setTypePets] = useState(null);
    const [defaultValueSelect, setDefaultValueSelect] = useState(null);
    const [defaultValueSelectSize, setDefaultValueSelectSize] = useState(null);
    const [step, setStep] = useState(0)
    const [dogName, setDogName] = useState('')
    const [dogGender, setDogGender] = useState('')
    const [adoptionDate, setAdoptionDate] = useState('')
    const [otherAllergy, setOtherAllergy] = useState('')
    const [allergies, setAllergies] = useState([])
    const [userLocation, setUserLocation] = useState(null);
    const [address, setAdress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [priceDelivery, setPriceDelivery] = useState(null);
    const [totalPrice, setTotalPrice] = useState(80);
    const [lead, setLead] = useState({});
    const [coordinates, setCoordinates] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [errorEmail, seterrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checktyc, setChecktyc] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [banner1,banner2];

    useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 600);

		return () => clearInterval(interval);
	}, [images.length]);

    const handleAddressChange = (event) => {
        const value = event.target.value;
        setAdress(value);
        if (value.length > 3) {
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({
                address: value,
                componentRestrictions: {
                    country: "PE",
                },
                }, (results, status) => {
                    setSuggestions(results || []);
            });
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setAdress(suggestion.formatted_address);
        setSuggestions([]);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: suggestion.place_id }, async(results, status) => {
            if (status === 'OK' && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                const coords = { lat: lat(), lng: lng() }
                setCoordinates(coords);
                const getPriceDelivery_ = await getPriceDelivery(coords);
                if(getPriceDelivery_.status === "ok"){
                    setPriceDelivery(getPriceDelivery_.data.priceDelivery)
                    setTotalPrice(getPriceDelivery_.data.priceDelivery  <= 20 ? getPriceDelivery_.data.priceDelivery + 80 : 80 )
                }
                handleLeadChange('latitude', lat().toString());
                handleLeadChange('longitude', lng().toString());
            }
        });
    };

    const handleLeadChange = (field, value) => {
        setLead((prevLead) => ({
            ...prevLead,
            [field]: value,
        }));
    };

    const processDataSearch = async() =>{
        const { typePets } = await usePetTypes();
        setTypePets(typePets);
    }

    const fetchData = async () => {
        try {
            await processDataSearch();
        } catch (error) {
            console.error("Error fetching store when user is logged:", error);
            setMessageToast("¡Lo sentimos! tuvimos problemas al solicitar la informaciópn del usuario.")
            if (messageToast) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                    toast.show();
                }
            }
        }
    };

    const handleCheckboxChange = (e) => {
		setChecktyc(e.target.checked);
	};

    useEffect(() => {
        fetchData();
        getUserLocation();
    }, [user]);

    const handleContinue = async () => {
        let canProceed = false;
        let updatedLead = { ...lead };

        const updateLead = (field, value) => {
            updatedLead = { ...updatedLead, [field]: value };
        };

        switch (step) {
            case 0:
                canProceed = true;
                break;
            case 1:
                canProceed = !!defaultValueSelect;
                break;
            case 2:
                if (dogName && dogGender && defaultValueSelect) {
                    updateLead('typePet', defaultValueSelect?.value);
                    updateLead('name', dogName);
                    updateLead('gender', dogGender);
                    try {
                        const leadResponse = await saveFirubox({ lead: updatedLead });
                        updateLead('id', leadResponse?.data?.firubox?.id);
                        canProceed = true;
                    } catch (error) {
                        console.error("Error saving Firubox:", error);
                        setMessageToast("¡Lo sentimos! Ocurrió un error, por favor inténtelo en unos minutos.")
                        if (messageToast) {
                            const toastEl = $('#liveToast')[0];
                            if (toastEl) {
                                let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                                toast.show();
                            }
                        }
                    }
                }
                break;
            case 3:
                canProceed = !!defaultValueSelectSize;
                break;
            case 4:
                canProceed = !!adoptionDate;
                break;
            case 5:
                canProceed = allergies.length > 0;
                break;
            case 6:
                if (coordinates && address && defaultValueSelectSize) {
                    setLoading(true)
                    updateLead('size', defaultValueSelectSize.value);
                    updateLead('birthDate', adoptionDate);
                    updateLead('address', address);
                    updateLead('email', email);
                    updateLead('phone', phone);
                    updateLead('totalPrice', totalPrice);
                    const updatedAllergies = [...allergies, otherAllergy].filter(Boolean);
                    const allergiesText = updatedAllergies.join(', ');
                    updateLead('allergies', allergiesText);

                    try {
                        await saveFirubox({ lead: updatedLead });
                        canProceed = true;
                        setLoading(false)
                    } catch (error) {
                        console.error("Error saving Firubox:", error);
                        setMessageToast("¡Lo sentimos! Ocurrió un error, por favor inténtelo en unos minutos.")
                        if (messageToast) {
                            const toastEl = $('#liveToast')[0];
                            if (toastEl) {
                                let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                                toast.show();
                            }
                        }
                    }
                }
                break;
        }

        if (canProceed) {
            setStep(prevStep => prevStep + 1);
            setLead(updatedLead);
        }
    };

    const handleCReturn = () => {
        setStep(step - 1)
    }

    const handleNext = () => {
        setStep(step + 1)
    }

    const handleChangePetType = async(selectedOption) => {
        setDefaultValueSelect(selectedOption)
    };

    const handleChangePetSize = async(selectedOption) => {
        setDefaultValueSelectSize(selectedOption)
    };

    const handleInputChange = (e) => {
        const input = e.target.value.replace(/\D/g, '')
        let formattedDate = ''
        if (input.length <= 2) {
            formattedDate = input
        } else if (input.length <= 6) {
            formattedDate = `${input.slice(0, 2)}/${input.slice(2)}`
        } else {
            formattedDate = `${input.slice(0, 2)}/${input.slice(2, 6)}`
        }
        setAdoptionDate(formattedDate)
    }

    const handleEmailInputChange = (e) => {
        seterrorEmail(false)
        setEmail(e.target.value)
        if(!isValidEmail(e.target.value)){
            seterrorEmail(true)
        }
    }

    const handlePhoneInputChange = (e) => {
        setErrorPhone(false)
        setPhone(e.target.value)
        if(e.target.value.length !== 9){
            setErrorPhone(true)
        }
    }

    const handleAllergyChange = (allergy) => {
        if (allergy === 'Ninguna') {
            setAllergies(['Ninguna'])
        } else {
            setAllergies(prev => {
                if (!prev.includes('Otros')) {
                    setOtherAllergy('');
                }
                if (prev.includes('Ninguna')) {
                    return [allergy]
                }
                if (prev.includes(allergy)) {
                    return prev.filter(a => a !== allergy)
                }
                return [...prev, allergy]
            })
        }
    }

    const handleInputChangeOtherAllergy = (event) => {
        setOtherAllergy(event.target.value);
    };

    const onClickReset = () =>{
        setStep(0)
        setLead({})
    }

    const MarkerMap = () => (
        <div style={{
            width: '44px',
            height: '44px',
            backgroundImage: `url(${marker})`,
            backgroundSize: 'cover',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop:"-44px",
            marginLeft:"-22px",
            cursor:"pointer",
        }}>
        </div>
    );

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    return (
        <div className="Firubox">
            <div className='Content'>
                <div className="col-md-12">
                    <div className={`${(step !== 6 && step !== 0) ? 'dog-form' : 'dog-form-without-width' }`}>
                        {step === 0 && (
                            <div className="card-body">
                                <div className="image-container">
                                    {images.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`Slide ${index + 1}`}
                                            className={`image ${index === currentImageIndex ? "active" : ""}`}
                                        />
                                    ))}
                                </div>
                                <br/>
                                <div className="max-width-600">
                                    <Disclaimer text={"Todas las imágenes son referenciales."} />
                                    <ContinueButton isActive onClick={handleContinue} label={"Comprar"}/>
                                </div>
                            </div>
                        )}
                        {step === 1 && (
                            <div className="card-body">
                                <h4 className="card-title text-center mb-4">¿Qué tipo de mascota tienes?</h4>
                                <Select
                                    className="select-input"
                                    classNamePrefix="select-input"
                                    value={defaultValueSelect}
                                    defaultValue={defaultValueSelect}
                                    options={typePets}
                                    onChange={handleChangePetType}
                                    placeholder="Selecciona el tipo de mascota"
                                    name="petTypeProfile"
                                />
                                <br/>
                                <ContinueButton isActive={!!defaultValueSelect} onClick={handleContinue} />
                            </div>
                        )}
                        {step === 2 && (
                            <div className="card-body">
                                <h4 className="card-title text-center mb-4">¿Cuál es el nombre de tu mascota?</h4>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Nombre de la mascota"
                                    value={dogName}
                                    onChange={(e) => setDogName(e.target.value)}
                                />
                                <div className="col-md-12">
                                    <button
                                        className={`form-control-buttom col-md-6 btn ${dogGender === 'male' ? 'btn-primary' : 'btn-outline-primary'}`}
                                        onClick={() => setDogGender('male')}
                                    >
                                        Es macho
                                    </button>
                                    <button
                                        className={`form-control-buttom col-md-6 btn ${dogGender === 'female' ? 'btn-primary' : 'btn-outline-primary'}`}
                                        onClick={() => setDogGender('female')}
                                    >
                                        Es hembra
                                    </button>
                                </div>
                                <br/>
                                <ContinueButton isActive={!!(dogGender && dogName)} onClick={handleContinue} />
                                <ReturnButton onClick={handleCReturn} />
                            </div>
                        )}
                        {step === 3 && (
                            <div className="card-body">
                                <h4 className="card-title text-center mb-4">¿Cuán grande es {dogName}?</h4>
                                <Disclaimer text={"Todos los productos tienen el mismo precio, independientemente del tamaño de tu mascota, puedes cambiar de tamaño en cualquier momento."} />
                                <Select
                                    className="select-input"
                                    classNamePrefix="select-input"
                                    value={defaultValueSelectSize}
                                    defaultValue={defaultValueSelectSize}
                                    options={arraySize}
                                    onChange={handleChangePetSize}
                                    placeholder="Selecciona el tamaño de tu mascota"
                                    name="petTypeProfile"
                                />
                                <br/>
                                <ContinueButton isActive={!!defaultValueSelectSize} onClick={handleContinue} />
                                <ReturnButton onClick={handleCReturn} />
                                <ReturnButton onClick={handleNext} label="Omitir" isRightAligned />
                            </div>
                        )}
                        {step === 4 && (
                            <div className="card-body">
                                <h4 className="card-title text-center mb-4">¿Cúando es el cumpleaños de {dogName}?</h4>
                                <Disclaimer text={'Puede ser el día de su nacimiento o el de su adopción. <span class="bold">¡Queremos celebrar ese día tan especial contigo!</span>'} />
                                <input
                                    type="text"
                                    className="form-control custom-input"
                                    placeholder="MM/YYYY"
                                    value={adoptionDate}
                                    onChange={handleInputChange}
                                    maxLength={7}
                                />
                                <br/>
                                <ContinueButton isActive={!!adoptionDate} onClick={handleContinue} />
                                <ReturnButton onClick={handleCReturn} />
                                <ReturnButton onClick={handleNext} label="Omitir" isRightAligned />
                            </div>
                            )}
                        {step === 5 && (
                            <div className="card-body">
                                <h4 className="card-title text-center mb-4">¿{dogName} tiene alguna alergia?</h4>
                                <Disclaimer text={"Si tu mascota tiene alguna necesidad especial de alimentación, ¡Haremos todo lo posible para adaptarnos!"} />
                                <div className="grid grid-cols-2 gap-2 mb-4">
                                {['Pollo', 'Pavo', 'Carne', 'Otros', 'Ninguna'].map(allergy => (
                                <div
                                    key={allergy}
                                    className={`flex items-center p-2 border rounded cursor-pointer transition-colors ${
                                    allergies.includes(allergy) ? 'bg-blue-600 text-white' : 'bg-white'
                                    }`}
                                    onClick={() => handleAllergyChange(allergy)}
                                >
                                    {allergies.includes(allergy) && <span className="text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M19 7L9.375 16L5 11.9091" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>}
                                    {allergy}
                                </div>
                                ))}
                                <br/>
                                {
                                    allergies.includes('Otros') &&
                                    <input
                                        type="text"
                                        className="form-control custom-input"
                                        placeholder="Otra alergia"
                                        value={otherAllergy}
                                        onChange={handleInputChangeOtherAllergy}
                                    />
                                }
                                <br/>
                            </div>
                                <ContinueButton isActive={!!allergies.length > 0} onClick={handleContinue} />
                                <ReturnButton onClick={handleCReturn} />
                                <ReturnButton onClick={handleNext} label="Omitir" isRightAligned />
                            </div>
                        )}
                        {step === 6 && (
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm">
                                        <h4 className="card-title text-center mb-4">Déjanos tus datos de contacto</h4>
                                        <input
                                            type="email"
                                            className={`form-control custom-input ${errorEmail ? 'error_input' : ''}`}
                                            placeholder="Correo electrónico"
                                            value={email}
                                            onChange={handleEmailInputChange} />
                                        <br/>
                                        <input
                                            type="email"
                                            className={`form-control custom-input ${errorPhone ? 'error_input' : ''}`}
                                            placeholder="Número de celular"
                                            value={phone}
                                            onChange={handlePhoneInputChange} />
                                        <br/>
                                        <hr style={{ border: "1px solid #C1C1C1", marginBottom: "30px" }}/>
                                        <h4 className="card-title text-center mb-4">¿Donde entregaremos la firubox para {dogName}?</h4>
                                        <input
                                            type="text"
                                            className="form-control custom-input"
                                            placeholder="Dirección de entrega"
                                            value={address}
                                            onChange={handleAddressChange}
                                        />
                                        <div className="suggestions">
                                            {suggestions.map((suggestion) => (
                                                <div
                                                    key={suggestion.place_id}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                    style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
                                                >
                                                    {suggestion.formatted_address}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="maps-content-service" tabIndex={-1}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                                options={{gestureHandling: 'greedy'}}
                                                center={coordinates || userLocation || defaultProps.center}
                                                yesIWantToUseGoogleMapApiInternals
                                            >
                                                {
                                                    coordinates &&
                                                        <MarkerMap
                                                            lat={coordinates?.lat}
                                                            lng={coordinates?.lng}
                                                        />
                                                }
                                            </GoogleMapReact>
                                        </div>
                                        <br/>
                                    </div>
                                    <div className="col-sm checkout">
                                        <div className="header-checkout">
                                            <div className='coontent-1-left'>
                                                <div className='coontent-1-left_'>
                                                    <img src={firubox_checkout} alt="firubox-img" />
                                                </div>
                                                <div className='coontent-1-right_'>
                                                    <div className="title">FiruBox</div>
                                                    <div className="type_pet">{defaultValueSelect?.value} Box</div>
                                                </div>
                                            </div>
                                            <div className='coontent-1-right'>
                                                <div className="title total-price-element">S/{parseFloat(totalPrice).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <hr style={{ border: "1px solid #C1C1C1", marginBottom: "30px" }}/>
                                        <div className="body-checkout">
                                            <div className='coontent-1-left'>
                                                <div className="type_pet">Precio Firubox</div>
                                            </div>
                                            <div className='coontent-1-right'>
                                                <div className="price-element">S/80.00</div>
                                            </div>
                                            <div className='coontent-1-left'>
                                                <div className="type_pet">Costo de envío</div>
                                            </div>
                                            <div className='coontent-1-right'>
                                                <div className={`price-element ${priceDelivery > 20 && 'strikethrough'}`}>S/{parseFloat(priceDelivery || 0).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        {
                                            priceDelivery > 20 &&
                                                <Disclaimer text={"La dirección ingresada está fuera de nuestra cobertura de entrega. No te preocupes!, puedes continuar con tu solicitud y nuestro equipo de soporte se contactará contigo para coordinar el método de envío ideal para ti."} />
                                        }
                                        <div className="check-terms">
                                            <input
                                                type="checkbox"
                                                name="check"
                                                id="tyc"
                                                checked={checktyc}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="tyc">Acepto <a href="/tyc" target="_blank" >Términos y condiciones</a></label>
                                        </div>
                                        <ContinueButton isActive={!!(isValidEmail(email) && phone.length === 9 && coordinates && totalPrice && checktyc)} onClick={handleContinue} />
                                    </div>
                                </div>
                                <ReturnButton onClick={handleCReturn} />
                            </div>
                        )}
                        {step === 7 && (
                            <div className="card-body">
                                <div className="div-check">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M38.0007 3.95801C19.2002 3.95801 3.95898 19.1992 3.95898 37.9997C3.95898 56.8002 19.2002 72.0413 38.0007 72.0413C56.8012 72.0413 72.0423 56.8002 72.0423 37.9997C72.0423 19.1992 56.8012 3.95801 38.0007 3.95801ZM23.8457 37.9047C23.3954 37.4852 22.7999 37.2568 22.1847 37.2676C21.5694 37.2785 20.9823 37.5277 20.5472 37.9629C20.112 38.398 19.8628 38.9851 19.8519 39.6004C19.8411 40.2156 20.0695 40.8111 20.489 41.2613L29.989 50.7613C30.4343 51.2061 31.0379 51.4559 31.6673 51.4559C32.2967 51.4559 32.9003 51.2061 33.3457 50.7613L55.5123 28.5947C55.9318 28.1445 56.1602 27.549 56.1494 26.9337C56.1385 26.3184 55.8893 25.7313 55.4541 25.2962C55.019 24.8611 54.4319 24.6118 53.8166 24.601C53.2014 24.5901 52.6059 24.8185 52.1557 25.238L31.6673 45.7263L23.8457 37.9047Z" fill="#8BD86F"/>
                                    </svg>
                                </div>
                                <h4 className="card-title text-center mb-4">¡Felicidades!</h4>
                                <p className="disclaimer2">Estamos preparando la Firubox para {dogName}. Muy pronto, un asesor se pondrá en contacto contigo para validar la información que nos has proporcionado.</p>
                                <p className="disclaimer3">Te hemos enviado un correo a: {email}</p>
                                <button className="btn btn-primary w-100" onClick={onClickReset}>
                                    Ir al inicio
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastModal message={messageToast}/>
            {
                loading && <Loading />
            }
        </div>
    );
}

export default FiruBox;
